<template>
  <div>
    <NavBar class="navbar-color" >
      <div slot="title">余额提现</div>
    </NavBar>
    <div class="operation-layout">
      <div class="operation-details">
        <van-icon name="arrow" size="20" color="#AAAAAA" class="select-pay" />
        <div class="go-take">提现到</div>
        <div class="wallet-address">
          <div class="choose-wallet">
            <div class="icon-img-box">
              <img src="~@/assets/order/wechat_icon@2x.png" class="icon-img" />
            </div>
            <div class="wallet-title">微信钱包</div>
          </div>
          <!-- 这里的右箭头暂时隐藏，暂时还不确定是否需要用到支付宝 -->
          <van-icon name="arrow" v-if="false" />
        </div>
        <div class="expect-time">预计3小时到账</div>
      </div>
      <div class="take-money">
        <div class="take-money-title">提现金额</div>
        <div class="input-money">
          <span>￥</span>
          <input type="number" v-model="inputValue" class="input-size" />
        </div>
        <div class="available-money">可用余额￥{{ money }}</div>
      </div>
      <div class="ok-button">
        <van-button type="danger" round block @click="confirmBtn">
          确认提现
        </van-button>
      </div>
    </div>
    <!-- 支付密码输入框 -->
    <van-popup v-model="showPopup">
      <div class="password-box">
        <div class="password-title">
          <van-icon
            name="cross"
            class="close-icon-img"
            @focus="inputFocus"
            @click="onClose"
          />
          <span class="title">请输入余额支付密码</span>
        </div>
        <div class="password-money">￥{{ twoNum(inputValue) }}</div>
        <div>
          <van-password-input
            :value="payPassword"
            :focused="showKeyboard"
            @focus="showKeyboard = true"
          />
        </div>
      </div>
    </van-popup>
    <van-number-keyboard
      v-model="value"
      :show="showKeyboard"
      theme="custom"
      extra-key="."
      close-button-text="完成"
      @blur="showKeyboard = false"
      @input="inputText"
      @delete="deleteText"
    />
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar.vue";
import { Toast, Dialog } from "vant";
import { setWithdraw, getWithdrawConfig } from "@/api/withdraw";
export default {
  name: "remain-cash",
  components: {
    NavBar,
  },
  data() {
    return {
      money: this.$route.query.money == undefined ? 0 : this.$route.query.money,
      inputValue: "", //input值
      payPassword: "", // 支付密码
      value: "",
      showKeyboard: false, // 键盘开关
      showPopup: false, //密码输入框
      isAjax: false,
    };
  },
  watch: {
    inputValue: function () {
      if (isNaN(this.inputValue)) {
        this.inputValue = "";
        return;
      }
      if (this.inputValue != "") {
        if (this.inputValue < 0) {
          this.inputValue = "";
        } else {
          if (this.inputValue * 1 >= this.money * 1) {
            this.inputValue = this.money * 1;
          }
        }
      }
    },
  },
  mounted() {
    // this.getWithdrawConfig();
  },
  methods: {
    onClose() {
      this.showPopup = false;
    },
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    confirmBtn() {
      if (this.inputValue && this.inputValue * 1 > 0) {
        // this.showPopup = true;
        this.setWithdraw();
      } else {
        Toast.fail("请输入提现金额");
      }
    },
    inputFocus() {
      this.showKeyboard = true;
    },
    inputText(key) {
      // 输入的时候触发
      if (this.payPassword.length < 6) {
        this.payPassword += key;
      }
      if (this.payPassword.length == 6) {
        //触发提现
        this.setWithdraw();
        this.showKeyboard = false;
      }
    },
    async setWithdraw() {
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      const ret = await setWithdraw({
        pay_pass: this.payPassword,
        money: this.inputValue,
      });
      if (ret.code * 1 === 1) {
        Toast.success(ret.msg);
        const that = this;
        setTimeout(function () {
          that.$router.replace({
            name: "remaining-sum",
            query: { moneyType: 1 },
          });
        }, 2000);
      } else {
        Toast.fail(ret.msg);
        this.inputValue = "";
        this.payPassword = "";
      }
      this.showPopup = false;
      this.isAjax = false;
    },
    deleteText() {
      // 删除的时候触发
      let passNum = this.payPassword.length;
      if (passNum > 1) {
        this.payPassword = this.payPassword.substr(0, passNum - 1);
      } else {
        this.payPassword = "";
      }
    },
    async getWithdrawConfig() {
      const { data } = await getWithdrawConfig();
      // console.log(data);
      if (data.set_pay_pass <= 0) {
        Dialog.confirm({
          title: "提示",
          message: "请设置支付密码",
          cancelButtonText: "返回",
          confirmButtonText: "设置密码",
        })
          .then(() => {
            this.$router.replace("/alter-password");
          })
          .catch(() => {
            this.$router.go(-1);
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.input-size {
  width: 90%;
}
.navbar-color {
  background-color: #fff;
}
.operation-layout {
  padding: 68px 12px 12px 12px;
}
// 提现到哪里
.operation-details {
  position: relative;
  font-size: 17px;
  .select-pay {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  .wallet-address {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    .choose-wallet {
      display: flex;
      align-items: center;
      .icon-img-box {
        width: 20px;
        margin-right: 10px;
        .icon-img {
          display: block;
          width: 100%;
        }
      }
    }
  }
  .expect-time {
    font-size: 16px;
    color: #aaaaaa;
    padding-left: 30px;
  }
}
// 提现金额
.take-money {
  padding: 12px;
  margin-top: 12px;
  background-color: #fff;
  border-radius: 16px;
  color: #aaaaaa;
  .take-money-title {
    font-size: 17px;
  }
  .input-money {
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid #ededed;
    margin: 48px 0 12px 0;
    color: #000;
    input {
      border: none;
      font-size: 30px;
    }
    span {
      font-size: 30px;
      margin-right: 10px;
    }
  }
}
.ok-button {
  padding: 10px 20px;
}
// 密码弹出层
/deep/.van-overlay {
  z-index: 99 !important;
}
.van-popup {
  border-radius: 16px;
}
.van-popup--center {
  top: 36%;
}
.password-box {
  width: 322px;
  height: 194px;
  padding: 14px;
  box-sizing: border-box;
  .password-title {
    position: relative;
    text-align: center;
    font-size: 20px;
    color: #6f6f6f;
    .close-icon-img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .password-money {
    font-size: 26px;
    width: 100%;
    text-align: center;
    padding: 12px 0 36px 0;
  }
}
.available-money {
  font-size: 16px;
}
</style>
